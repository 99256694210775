import React, {useEffect, useState} from "react";

import {Translator} from "../translator/Translator";
import {translate} from "../translator/translate";


const getConfirmation = () => {
	if(localStorage.getItem('ProfessionalInfoPopupConfirmed') != '1'){
		return false;
	}
	return true;
}
export default function ProfessionalInfoPopup(props) {

	const [visible, setVisible] = useState(!getConfirmation());

	const setConfirmation = () => {
		localStorage.setItem('ProfessionalInfoPopupConfirmed', '1');
		setVisible(false);
	};

	if(!visible){
		return <></>;
	}

	return (
		<div className="ProfessionalInfoPopup">
			<div className="ProfessionalInfoPopup__content">
				<p><Translator location="ProfessionalInfoPopup.1"/></p>
				<p><Translator location="ProfessionalInfoPopup.2"/></p>

				<button onClick={setConfirmation} type="button" className="btn-gradient-solid ProfessionalInfoPopup__content__btn">
					<span><Translator location="ProfessionalInfoPopup.button"/></span>
				</button>
			</div>
		</div>
	)

}
