import React from 'react';


export default class ListingPage extends React.Component{
    //FIXME with PL 8% VAT it has wrong sequence of calculations
    rawTotal(){
        let total = 0;
        const products = this.props.cart;
        for(let i=0; i < products.length; i++){
            let price = products[i].price * products[i].quantity;
            if(this.props.coupon){
                if(this.props.coupon.percent){
                    price *= (100 - this.props.coupon.percent)/100
                }
            }
            total += price;
        }

        return total;
      }
}
