import React from "react";
import Request from "../api/Request";
// import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import colors from "../colors/colors";
import { ChevronLeft } from "react-bootstrap-icons";
import { ReactComponent as Facebook } from "../svg/facebook.svg";
import { ReactComponent as Linkedin } from "../svg/linkedin.svg";
import {Translator} from "../components/translator/Translator";
// import colors from "../colors/colors";
// import {connect} from "react-redux";
// import {isMobileFetched} from "../actions";

export default class Article extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            article: false,
        };
    }

    redirectHome(){
        window.location.href = '/';
    }

    componentDidMount() {
        if(!this.props.match.params.articleUri){
            this.redirectHome();
        }

        Request.getArticleByUri(this.props.match.params.articleUri, (response) => {
            if(!response.success){
                this.redirectHome();
            }else{
                this.setState({
                    article: response.article,
                });
            }
        })
    }


    render() {

        if(!this.state.article){
            return null;
        }

        return (
            <div className="Article">

                <div className="container">
                    <Link to="/articles" className="Article__back">
                        <ChevronLeft color={colors.blue} />
                    </Link>


                    <h1 className="Article__title title-montserrat" dangerouslySetInnerHTML={{ __html: this.state.article.title }}></h1>

                    <div className="Article__cover-row">
                        <div className="Article__cover-wrapper">
                            <img src={this.state.article.cover} alt={this.state.article.title} className="Article__cover"/>
                        </div>
                    </div>



                    <div className="Article__content description" dangerouslySetInnerHTML={{ __html: this.state.article.content }}></div>

                    <div className="Article__content">
                        <div className="Article__share">
                            <h6 className="Article__share__title"><Translator location="Articles.share"/></h6>
                            <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} target="_blank">
                                <Facebook/>
                            </a>
                            <a href={"https://www.linkedin.com/sharing/share-offsite/?url="+window.location.href} target="_blank">
                                <Linkedin/>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

