export const Tools = {
    scrollToElement(selector) {
        const element = document.querySelector(selector);
        if(element){
            element.scrollIntoView({behavior: "smooth"})
        }
    },

    redirect(link){
        if(link){
            window.location.href = link;
        }
    }
}
