import React, {lazy, Suspense} from "react";
import logo from "./logo.svg";
import "./App.scss";
import 'swiper/swiper.scss';

// import "bootstrap/dist/css/bootstrap.min.css";
// import MainHeader from "./components/headers/MainHeader";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Container} from "react-bootstrap";

// import HomePage from "./pages/HomePage";

// import MainFooter from "./components/footers/MainFooter";
import {ParallaxProvider} from "react-scroll-parallax";
// import OurStoryPage from "./pages/OurStoryPage";
// import CategoryProductListPage from "./pages/CategoryProductListPage";
// import BrandProductListPage from "./pages/BrandProductListPage";
// import ProductPage from "./pages/ProductPage";
// import YourCartPage from "./pages/YourCartPage";
// import PreCheckoutPage from "./pages/PreCheckoutPage";
// import CheckoutPage from "./pages/CheckoutPage";
// import MyAccountPage from "./pages/MyAccountPage";
// import HistoricalOrder from "./pages/HistoricalOrder";
// import ContactPage from "./pages/ContactPage";
// import DeliveryInfoPage from "./pages/DeliveryInfoPage";
// import CompanyInfoPage from "./pages/CompanyInfoPage";
// import LogInPage from "./pages/LogInPage";
// import CurrencyPopup from "./components/general/CurrencyPopup";
import {connect} from "react-redux";
import {
    isMobileFetched,
    userFetched,
    showCurrencyPopupFetched,
    showNewsletterPopupFetched,
    localeFetched,
    cartFetched,
    euCountryFetched,
    stateCountryFetched,
    couponFetched,
    isClientPLFetched,
    menuFetched,
    configurationFetched
} from "./actions";
// import ResetPasswordPage from "./pages/ResetPasswordPage";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import CookiesPolicy from "./pages/CookiesPolicy";
// import TermsAndConditions from "./pages/TermAndCondition";
// import PaymentInfoPage from "./pages/PaymentInfoPage";
// import NewsletterPopup from "./components/general/NewsletterPopup";
import Request from "./api/Request";
// import CookieBar from "./components/general/CookieBar";
// import MensProductListPage from "./pages/MensProductListPage";
import MobileMenu from "./components/mobile/MobileMenu";
// import ThankYouPage from "./pages/ThankYouPage";
// import FailedPaymentPage from "./pages/FailedPaymentPage";
// import RestoreProductListPage from "./pages/RestoreProductListPage";
// import RefreshProductListPage from "./pages/RefreshProductListPage";
// import ReactPixel from 'react-facebook-pixel';
import PageChange from "./components/general/PageChange";
// import v2 from "./pages/HomePage/v2";
import stateCountries from "./stateCountries";
import euCountries from "./euCountries";
import Article from "./pages/Article";
import {Storage} from "./api/Storage";
import FullSiteWrapper from "./components/blocks/FullSiteWrapper";
import ProfessionalInfoPopup from "./components/form/ProfessionalInfoPopup";
// import request from "./api/Request";
// import RetryPayment from "./pages/RetryPayment";
// import RegisterPage from "./pages/RegisterPage";
// import Page404 from "./pages/Page404";
// import SearchPage from "./pages/SearchPage";
// import DiscountPage from "./pages/DiscountPage";


const v2 = lazy(() => import('./pages/HomePage/v2'));
const OurStoryPage = lazy(() => import('./pages/OurStoryPage'));
const CategoryProductListPage = lazy(() => import('./pages/CategoryProductListPage'));
const BrandProductListPage = lazy(() => import('./pages/BrandProductListPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const YourCartPage = lazy(() => import('./pages/YourCartPage'));
const PreCheckoutPage = lazy(() => import('./pages/PreCheckoutPage'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const MyAccountPage = lazy(() => import('./pages/MyAccountPage'));
const HistoricalOrder = lazy(() => import('./pages/HistoricalOrder'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const DeliveryInfoPage = lazy(() => import('./pages/DeliveryInfoPage'));
const CompanyInfoPage = lazy(() => import('./pages/CompanyInfoPage'));
const LogInPage = lazy(() => import('./pages/LogInPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy'));
const TermsAndConditions = lazy(() => import('./pages/TermAndCondition'));
const PaymentInfoPage = lazy(() => import('./pages/PaymentInfoPage'));
const MensProductListPage = lazy(() => import('./pages/MensProductListPage'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const FailedPaymentPage = lazy(() => import('./pages/FailedPaymentPage'));
const RestoreProductListPage = lazy(() => import('./pages/RestoreProductListPage'));
const RefreshProductListPage = lazy(() => import('./pages/RefreshProductListPage'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const DiscountPage = lazy(() => import('./pages/DiscountPage'));
const SkinBoostingPage = lazy(() => import('./pages/SkinBoostingPage'));
const AllProductsPage = lazy(() => import('./pages/AllProductsPage'));
const MainFooter = lazy(() => import('./components/footers/MainFooter'));
const MobileFooter = lazy(() => import('./components/footers/MobileFooter'));
const CurrencyPopup = lazy(() => import('./components/general/CurrencyPopup'));
const NewsletterPopup = lazy(() => import('./components/general/NewsletterPopup'));
const CookieBar = lazy(() => import('./components/general/CookieBar'));
const MainHeader = lazy(() => import('./components/headers/MainHeader'));
const Page404 = lazy(() => import('./pages/Page404'));
const Articles = lazy(() => import('./pages/Articles'));
const RetryPayment = lazy(() => import('./pages/RetryPayment'));

export class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptCookies: false,
            password: '',
            passwordInput: '',
        };
    }


    setFetchLocale(locale) {
        this.props.localeFetched({...locale});
        this.props.euCountryFetched(euCountries.includes(locale.country));
        this.props.stateCountryFetched(stateCountries.includes(locale.country));
        window.localStorage.setItem('locale', JSON.stringify(locale));

    }


    getUserLocale(availableCountries) {
        return new Promise((resolve) => {
            let locale = JSON.parse(window.localStorage.getItem('locale'));
            let userIpCountryData = Storage.getUserIpCountryData();
            this.props.isClientPLFetched(Request.isClientPL());

            if(!locale){
                locale = {
                    country: 'Poland',
                    currency: 'eur',
                    euCountry: true
                };
            }


            if (!userIpCountryData.ip) {


                Request.getLocaleByIP((response) => {
                    if (response.location && response.location.country && response.location.country.name && response.location.country.code) {
                        availableCountries.forEach((country) => {
                            if(country.alpha2Code == response.location.country.code){
                                locale.country = country.name;
                            }
                        });
                        let userIpData = {ip: response.ip, isPL: (response.location.country.code == "PL")};
                        Storage.setUserIpCountryData(userIpData);
                        this.props.isClientPLFetched(userIpData.isPL);
                        // locale.country = response.location.country.name;
                    }else{
                        Storage.setUserIpCountryData({ip: '0.0.0.0', isPL: true});
                        this.props.isClientPLFetched(true);
                    }
                    if (response.currency && response.currency.code) {
                        Request.getAvailableCurrencies().forEach((LoopCurrency) => {
                            if (LoopCurrency.value === response.currency.code.toLowerCase()) {
                                locale.currency = response.currency.code.toLowerCase();
                            }
                        });
                    }

                    return resolve(locale);
                }, (error) => {
                    console.log(error);
                    return resolve(locale);
                });

            }else{
                return resolve(locale);
            }

        })
    }


    getCouponFetched(){
        let coupon = {};
        let code = window.localStorage.getItem('coupon_code');
        if(code){
            Request.getCoupon(code, (response) => {
                if(response.success){
                    coupon = response.coupon;
                    this.props.couponFetched(coupon);
                }
            });
        }
    }

    getMenuFetched(){
        Request.getMenu((response) => {
            if(response.success){
                // console.log(response.menu);
                this.props.menuFetched(response.menu);
            }
        });
    }
    getConfigurationFetched(){
        Request.getConfiguration((response) => {
            const preparedConfiguration = {};
            response.data.forEach((configuration) => {
                preparedConfiguration[configuration.key] = configuration.value;
            });
            // console.log(this.props);
            // console.log(preparedConfiguration);
            this.props.configurationFetched(preparedConfiguration)
        })
    }

    componentDidMount() {

        this.props.isMobileFetched(window.innerWidth < 992);
        let user = JSON.parse(window.localStorage.getItem('user'));
        let cart = JSON.parse(window.localStorage.getItem('cart'));

        this.getCouponFetched();
        this.getMenuFetched();
        this.getConfigurationFetched();

        Request.getCountries((countries) => {
            this.getUserLocale(countries).then((locale) => {
                if (locale) {
                    this.props.showCurrencyPopupFetched(false);
                    this.setFetchLocale(locale);
                }
            });

        });


        if (user) {
            this.props.userFetched(user);
            Request.setBearerFromStorage(user.access_token);
        }
        if (cart) {
            for (let cartItemIndex = 0; cartItemIndex < cart.length; cartItemIndex++) {
                if (cart[cartItemIndex].quantity < 1) {
                    cart.splice(cartItemIndex, 1)
                }
            }
            this.props.cartFetched(cart);
        }

        let cookies = JSON.parse(window.localStorage.getItem('cookies'));
        if (cookies) {
            this.setState({acceptCookies: true});
        }

        let pwd = JSON.parse(window.localStorage.getItem('password'));
        if (pwd) {
            this.setState({password: pwd});
        }

        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };


        if (!Request.isUserAgentBot()) {
            import('react-facebook-pixel').then(({default: ReactPixel}) => {
                ReactPixel.init('2092347764242773', {}, options);
                ReactPixel.pageView();
            });
        }


        this.setIsMobile();
    }

    setIsMobile() {
        window.addEventListener('resize', () => {
            this.props.isMobileFetched(window.innerWidth < 992);
        })
    }

    setAcceptCookies() {
        this.setState({acceptCookies: true});
        window.localStorage.setItem('cookies', JSON.stringify(true));
    }

    setPassword() {
        this.setState({password: this.state.passwordInput});
        window.localStorage.setItem('password', JSON.stringify(this.state.passwordInput));
    }



    render() {
        // if (this.state.password != 'Fraya!@2020') {
        //   return <div>
        //       <input type="text" value={this.state.passwordInput} onChange={e=> this.setState({passwordInput: e.target.value})}></input>
        //       <button onClick={this.setPassword.bind(this)}>submit</button>
        //   </div>
        // }
        return (
            <Router>
                <Suspense fallback={<></>}>
                    <PageChange/>
                    <Container
                        fluid
                        style={{maxWidth: "100vw", position: "absolute", zIndex: 4}}
                    >
                        {this.props.isMobile ? null : <MainHeader white={false}/>}
                        {/*{this.props.showCurrencyPopup ? <CurrencyPopup /> : <></>}*/}
                        {this.props.showNewsletterPopup ? <NewsletterPopup/> : <></>}
                        {this.props.isMobile ? <MobileMenu/> : <></>}
                    </Container>

                    <Container style={{maxWidth: "100vw", marginBottom: this.props.isMobile ? '0' : '0'}}>

                    </Container>

                    <FullSiteWrapper location={window.location.pathname}>
                        <ParallaxProvider>
                            <Switch>

                                <Redirect from="/brand/6-35-cytocare" to="/brand/3-35-cytocare" />
                                <Redirect from="/brand/4-43-hyacorp" to="/brand/3-43-hyacorp" />
                                <Redirect from="/brand/6-11-profhilo" to="/brand/3-11-profhilo" />
                                <Redirect from="/brand/14-11-profhilo" to="/brand/3-11-profhilo" />
                                <Redirect from="/brand/7-9-intraline" to="/brand/4-9-intraline" />
                                <Redirect from="/brand/14-10-jalupro" to="/brand/3-10-jalupro" />
                                <Redirect from="/brand/14-19-sculptra" to="/brand/4-19-sculptra" />


                                <Route exact path="/" component={v2}/>
                                <Route exact path="/about" component={OurStoryPage}/>
                                <Route exact path="/products/:typeUri" component={CategoryProductListPage}/>
                                <Route exact path="/brand/:brandUri" component={BrandProductListPage}/>
                                <Route exact path="/product/:productUri" component={ProductPage}/>
                                <Route exact path="/cart" component={YourCartPage}/>
                                <Route exact path="/pre-checkout" component={PreCheckoutPage}/>
                                <Route exact path="/checkout" component={CheckoutPage}/>
                                <Route exact path="/account" component={MyAccountPage}/>
                                <Route exact path="/history" component={HistoricalOrder}/>
                                <Route exact path="/contact" component={ContactPage}/>
                                <Route exact path="/delivery-info" component={DeliveryInfoPage}/>
                                <Route exact path="/company-info" component={CompanyInfoPage}/>
                                <Route exact path="/login" component={LogInPage}/>
                                <Route exact path="/register" component={RegisterPage}/>
                                <Route exact path="/reset-password" component={ResetPasswordPage}/>
                                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                                <Route exact path="/cookies-policy" component={CookiesPolicy}/>
                                <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
                                <Route exact path="/payment-info" component={PaymentInfoPage}/>
                                <Route exact path="/mens-range" component={MensProductListPage}/>
                                <Route exact path="/thank-you" component={ThankYouPage}/>
                                <Route exact path="/payment-error" component={FailedPaymentPage}/>
                                <Route exact path="/restore" component={RestoreProductListPage}/>
                                <Route exact path="/refresh" component={RefreshProductListPage}/>
                                <Route exact path="/search/:query" component={SearchPage}/>
                                <Route exact path="/discounts" component={DiscountPage}/>
                                <Route exact path="/skin-boosting" component={SkinBoostingPage}/>
                                <Route exact path="/all-products" component={AllProductsPage}/>
                                <Route exact path="/articles" component={Articles}/>
                                <Route exact path="/article/:articleUri" component={Article}/>
                                <Route exact path="/retry_payment/:orderToken" component={RetryPayment}/>
                                <Route path="*" component={Page404}/>
                            </Switch>
                        </ParallaxProvider>
                        {this.props.isMobile ? <MobileFooter/> : <MainFooter/>}
                    </FullSiteWrapper>
                    {this.state.acceptCookies ? <></> :
                        <CookieBar setAcceptCookies={this.setAcceptCookies.bind(this)}/>}

                    <ProfessionalInfoPopup/>
                </Suspense>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.isMobile, // (1)
        user: state.user,
        showCurrencyPopup: state.showCurrencyPopup,
        showNewsletterPopup: state.showNewsletterPopup,
        locale: state.locale,
        euCountry: state.euCountry,
        stateCountry: state.stateCountry,
        coupon: state.coupon,
        isClientPL: state.isClientPL,
        menu: state.menu,
        configuration: state.configuration
    };
};
const mapDispatchToProps = {
    isMobileFetched,
    userFetched,
    showCurrencyPopupFetched,
    showNewsletterPopupFetched,
    localeFetched,
    cartFetched,
    euCountryFetched,
    stateCountryFetched,
    couponFetched,
    menuFetched,
    isClientPLFetched,
    configurationFetched
}; // (2)

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App); // (3)

export function deepCopy(object){
    return JSON.parse(JSON.stringify(object));
}
