import React from "react";
import parse from 'html-react-parser';
import {translate} from "./translate";


export function Translator(props) {

	const {location, ...replace} = props;
	// const lang = 'it';
	// const fallback = 'en';

	return (
		<>
			{parse(translate(location, "", "", replace))}
		</>
	)
}
