import React from "react";
import { Scrollbars } from 'react-custom-scrollbars';

export default class CustomDropdown extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        }
    }

    closeOnOtherClick(ev){
        if(!ev.target.closest('.CustomDropdown') && !ev.target.classList.contains('.CustomDropdown')){
            this.setState({isOpen: false});
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.closeOnOtherClick.bind(this));
    }
    componentWillUnmount(){
        document.removeEventListener('click', this.closeOnOtherClick.bind(this));
    }


    handleSelect(value){
        this.props.onChange(value);
        this.setState({isOpen: false});
    }



    render() {
        let mainClass = "CustomDropdown";
        if(this.props.className)
            mainClass += " "+this.props.className;

        return (
            <div className={mainClass}>
                <button className="CustomDropdown__button" onClick={() => {this.setState({isOpen: !this.state.isOpen})}}>
                    {this.props.prefix} <strong>{this.props.value}</strong>
                </button>
                {this.state.isOpen ?
                    <Scrollbars
                        className={'CustomDropdown__scrollbar'}
                        autoHeight
                        autoHeightMax={200}
                        hideTracksWhenNotNeeded={true}
                    >
                        <ul className="CustomDropdown__list">
                            {this.props.options.map((option) => {
                                let className = '';
                                if(option.value === this.props.value) className = 'selected';
                                return (
                                    <li className={className} title={option.label || option.value} onClick={() => {this.handleSelect(option.value)}} key={option.value}>{option.label || option.value}</li>
                                )
                            })}
                        </ul>
                    </Scrollbars>
                : null}
            </div>
        );
    }
}
