const stateCountries = [
    'Australia',
    'Brazil',
    'India',
    'Malaysia',
    'Mexico',
    'Micronesia (Federated States of)',
    'Nigeria',
    'United States of America',
    'Sudan',
    'South Sudan',
    "Venezuela (Bolivarian Republic of)",
];

export default stateCountries;
