import en from "./translations/en.json";
import it from "./translations/it.json";

export function getFallbackLang(){
	return 'en';
}
export function getCurrentLang(){
	if(window.location.host === 'localhost:3000'){
		return 'en';
	}
	if(window.location.host === 'frayamedsupply.it'){
		return 'it';
	}
	return 'en';
}

export function translate(key, lang = "", fallback = "", replace = null){
	const translations = {
		en,
		it
	}

	if(!lang){
		lang = getCurrentLang();
	}
	if(!fallback){
		fallback = getFallbackLang();
	}

	const getNestedTranslation = (language, keys) => {
		return keys.reduce((obj, key) => {
			return obj?.[key]
		}, translations[language])
	}

	const keys = key.split('.');
	let translated = getNestedTranslation(lang, keys);
	if(!translated){
		translated = getNestedTranslation(fallback, keys);
	}
	if(!translated){
		translated = '';
	}

	if(replace){
		for(const key in replace){
			translated = translated.replaceAll(`%${key}%`, replace[key]);
		}
	}
	return translated;

}
