import R1A0448a1 from "../img/R1A0448a1.png";
import R1A0473a1 from "../img/R1A0473a1.png";
import R1A5543a1 from "../img/R1A5543a1.png";
import {Storage} from "./Storage";
import {translate} from "../components/translator/translate";

// const apiUrl = 'http://localhost:8080/apiV2/';
// const apiUrl = 'http://frayaserverlukasz/apiV2/';
// const apiUrl = "https://server.frayawarehouse.com/";

let apiUrl = "https://server.frayawarehouse.com/apiV2/";
let serverHostUrl = "https://server.frayawarehouse.com";
let stripeEnabled = false;

if (window.location.host === 'localhost:3000' || window.location.host === 'dev.frayamedsupply.com') {
    apiUrl = "https://devserver.frayawarehouse.com/apiV2/";
    serverHostUrl = "https://devserver.frayawarehouse.com";
    stripeEnabled = false;
}
if(window.location.host === 'frayamedsupply.it') {
    apiUrl = "https://itserver.frayawarehouse.com/apiV2/";
    serverHostUrl = "https://itserver.frayawarehouse.com";
}
// apiUrl = "https://itserver.frayawarehouse.com/apiV2/";

// const locationApiUrl = 'http://www.geoplugin.net/json.gp';
// const locationApiUrl = 'https://api.ipregistry.co/?key=1l1zeqpiw0in1rhf'; //bkteam api key
const locationApiUrl = 'https://api.ipregistry.co/?key=8n55prdj785q7v7n'; // fraya api key
const shopName = 'Fräya Med Supply';
// const apiUrl = "https://server.frayawarehouse.com/apiV2/";
// const apiUrl = "https://devserver.frayawarehouse.com/apiV2/";
let bearer = "";

//should be compatible with deliveryCostController::actionGetAllV2
let euCountriesList = [
    'Austria', 'Lithuania', 'Belgium', 'Luxembourg', 'Bulgaria', 'Latvia', 'Croatia', 'Malta',
    'Cyprus', 'Germany', 'Czech Republic', 'Denmark', 'Portugal', 'Estonia', 'Romania', 'Finland',
    'Slovakia', 'France', 'Slovenia', 'Greece', 'Sweden', 'Spain', 'Hungary', 'Netherlands',
    'The Netherlands', 'Italy', 'Ireland',
]
let viesCountries = [
    'Austria', 'Belgium', 'Bulgaria', 'Cyprus', 'Czech Republic', 'Germany', 'Denmark', 'Estonia', 'Greece', 'Spain',
    'Finland', 'France', 'Croatia', 'Hungary', 'Ireland', 'Italy', 'Lithuania', 'Luxembourg', 'Latvia', 'Malta',
    'Netherlands', 'Poland', 'Portugal', 'Romania', 'Sweden', 'Slovakia', 'Slovenia'
]

let exchange = null;
let deliveryCosts = {};

let countries = null;
const get = (callback, url, authenticated) => {
    return request(callback, {}, url, "GET", authenticated);
};

const post = (callback, params, url, authenticated, noLoader) => {
    return request(callback, params, url, "POST", authenticated, noLoader);
};

const request = (callback, params, url, method, authenticated, noLoader) => {
    // if (!noLoader) {
    // let loader = document.getElementById('loader');
    // loader.classList.add("loadderActive");
    // }
    if (typeof authenticated === "undefined") {
        authenticated = false;
    }
    const requestParams = {
        method: method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    if (authenticated) {
        requestParams.headers["Authorization"] = "Bearer " + bearer;
    }
    if (method == "POST") {
        requestParams.body = JSON.stringify(params);
    }
    fetch(apiUrl + url, requestParams)
        .then((response) => {
            if (response && response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                // response.json().then(data => console.log(data));
                return [];
            }
        })
        .then((response) => {
            //wyłącz loader
            // document.getElementById('loader').classList.remove("loadderActive");
            callback(response);
        })
        .catch((error) => console.error("Error:", error));
};

const Request = {

    productCache: {},

    checkIfProductVideoExist(id) {
        return new Promise((resolve) => {
            const url = serverHostUrl+`/product_videos/${id}.mp4`;

            fetch(url, {method: 'HEAD'}).then((response) => {
                if(response.status === 200){
                    resolve(url);
                }else{
                    resolve(false);
                }
            }).catch((reason) => {
                resolve(false);
            })


            // const xhttp = new XMLHttpRequest();
            // xhttp.open('HEAD', url);
            // xhttp.onreadystatechange = function () {
            //     if (this.readyState == this.DONE) {
            //         if(this.getResponseHeader("Content-Type") === 'video/mp4'){
            //             resolve(url);
            //         }else{
            //             resolve(false);
            //         }
            //     }
            // };
            // xhttp.send();
        })


    },

    getProductByID(id) {
        if(Request.productCache[id]){
            return Request.productCache[id];
        }
        return post((response) => {
            if(response.success){
                Request.productCache[response.product.id] = response.product;
            }
            return response;
        }, {id: id}, "product/get-by-id");
    },

    productIsNew(id) {
        return [314,315].indexOf(id) !== -1;
    },

    isViesCountry(country){
        return viesCountries.indexOf(country) !== -1;
    },

    getVatNumberInfo(){
        return translate("GlobalInfo.ask_vat");
    },

    getDeliverySplittedInfo(){
        return translate('GlobalInfo.prx_product');
        // return translate('GlobalInfo.prx');
    },
    isDeliverySplitted(cart){
        if(cart.length <= 1) return false;
        let hasPrx = false;
        cart.forEach((cartItem) => {
            if(Request.productIsSplitted(cartItem.id)){
                hasPrx = true;
            }
        });
        return hasPrx;
    },
    productIsSplitted(id_product){
        return id_product == 257;
    },

    getConfiguration(callback) {
        get((response) => {
            callback(response);
        }, "configuration/all");
    },

    getMenu(callback) {
        get((response) => {
            callback(response);
        }, "menu/get");
    },

    isVatNumberPL(vat_number){
        return vat_number.trim().toUpperCase().startsWith("PL");
    },

    isClientPL(){

        if(this.isVatNumberPL(Storage.getVatNumber())){
            return true;
        }

        let locale = JSON.parse(window.localStorage.getItem('locale'));
        if(Storage.getVatNumber() === '' && locale){
            return (locale.country === 'Poland');
        }

        if(Storage.getVatNumber() !== ''){
            return false;
        }
        return Storage.getUserIpCountryData().isPL;
    },

    countryNameIsEu(country){
        return (country === 'Poland' || euCountriesList.indexOf(country) !== -1);
    },


    isStripeEnabled(){
        return stripeEnabled
    },

    validateVatNumber(vat_number, callback) {
        post((response) => {
            callback(response);
        }, {vat_number: vat_number}, "order/check-vat");
    },

    getArticleByUri(uri, callback) {
        post((response) => {
            callback(response);
        }, {uri: uri}, "articles/get-by-uri");
    },


    getArticles(callback) {
        get((response) => {
            callback(response);
        }, "articles/get-all");
    },

    async getLocaleByIP(callback, errorCallback){
        return await fetch(locationApiUrl)
            .then((response) => {return response.json()})
            .then(callback)
            .catch(errorCallback)
    },


    validateCart(cart, callback) {
        let data = {
            cart: cart,
            bearer: bearer
        };
        post(
            (response) => {
                callback(response);
            },
            data,
            "cart/validate",
            true
        );
    },
    updateCart(cart, callback) {
        let data = {
            cart: cart,
            bearer: bearer
        };
        post(
            (response) => {
                callback(response);
            },
            data,
            "cart/update",
            true
        );
    },
    getCoupon(code, callback) {
        let data = {
            coupon: code
        };
        // console.log(data);
        post(
            (response) => {
                callback(response);
            },
            data,
            "order/get-coupon",
            false
        );
    },


    isUserAgentBot(){
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
    },

    getAvailableCurrencies() {
        return [
            {value: 'pln'},
            {value: 'eur'},
            {value: 'usd'},
            {value: 'gbp'},
        ];
    },


    getShopNameSuffix(){
        return " | "+shopName;
    },

    sendEmail(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "mail/send",
            false
        );
    },
    askAboutProduct(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "mail/ask-about-product",
            false
        );
    },
    askQuestion(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "mail/question",
            true
        );
    },
    login(email, password, callback) {
        post(
            (response) => {
                if (response.success) {
                    bearer = response.access_token;
                }
                callback(response);
            },
            {email: email, password: password},
            "auth/login",
            false
        );
    },
    register(email, password, callback, additionalData = {}) {
        post(
            (response) => {
                if (response.success) {
                    bearer = response.access_token;
                }
                callback(response);
            },
            {email: email, password: password, ...additionalData},
            "user/register",
            false
        );
    },
    registerFacebook(response, callback) {
        post(
            (response) => {
                if (response.success) {
                    bearer = response.access_token;
                }
                callback(response);
            },
            {response: response},
            "user/register-facebook",
            false
        );
    },
    registerGoogle(response, callback) {
        post(
            (response) => {
                if (response.success) {
                    bearer = response.access_token;
                }
                callback(response);
            },
            {response: response},
            "user/register-google",
            false
        );
    },
    // getProducts(callback) {
    //   get((response) => {
    //     callback(response);
    //   }, "product/get-all?frontend=1");
    // },
    saveMyData(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "user/update",
            true
        );
    },
    deleteAccount(callback) {
        post(
            (response) => {
                callback(response);
            },
            {},
            "user/delete",
            true
        );
    },
    resetPassword(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "auth/reset-password"
        );
    },
    doResetPassword(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "auth/do-reset-password"
        );
    },
    getOrderHistory(callback) {
        get((response) => {
            callback(response);
        }, "order/history", true);
    },
    getPaymentButton(id, callback) {
        post(
            (response) => {
                callback(response);
            },
            {orderId: id},
            "order/get-payment-link"
        );
    },
    getRefreshPaymentButton(order_token, callback) {
        post(
            (response) => {
                callback(response);
            },
            {order_token: order_token},
            "order/get-refresh-payment-link"
        );
    },
    getOrderDetailsByToken(order_token, callback) {
        post(
            (response) => {
                callback(response);
            },
            {order_token: order_token},
            "order/get-order-details-by-token"
        );
    },
    createOrder(data, callback) {
        data.bearer = bearer;
        post(
            (response) => {
                callback(response);
            },
            data,
            "order/create"
        );
    },
    createPayPalOrder(data, callback) {
        data.bearer = bearer;
        post(
            (response) => {
                callback(response);
            },
            data,
            "order/create-quick"
        );
    },
    orderFeedback(data, callback) {
        post(
            (response) => {
                callback(response);
            },
            data,
            "order/feedback"
        );
    },
    getCategories(callback) {
        get((response) => {
            callback(response);
        }, "category/get-all");
    },
    getPurposes(callback) {
        get((response) => {
            callback(response);
        }, "purpose/get-all");
    },
    realProductImage(url) {
        if (!url) {
            return url;
        }
        if (url.includes("http")) {
            return url;
        }
        return apiUrl.replace('/apiV2', '') + "/uploads/" + url;
    },
    getSearchHints(query, callback) {
        post(response => {
            callback(response);
        }, {query: query}, 'product/hint', true, true);
    },
    // B2B
    getSlides(callback) {
        callback({
            success: true,
            slides: [
                {
                    link: "/cokolwiek",
                    img: R1A0448a1,
                },
                {
                    link: "/kokok",
                    img: R1A0473a1,
                },
                {
                    link: "/aaa",
                    img:
                    R1A5543a1,
                },
            ],
        });
    },
    getProducts(callback) {
        get((response) => {
            callback(response);
        }, "product/get-all");
    },
    getSpecialOffer(callback) {
        get((response) => {
            callback(response);
        }, "product/get-special-offer");
    },
    getBestSellers(callback) {
        get((response) => {
            callback(response);
        }, "product/get-best-sellers");
    },
    getSectionProducts(type, callback) {
        let data = {
            type: type
        };
        post(
            (response) => {
                callback(response);
            },
            data,
            "product/get-section-products",
            false
        );
    },
    getOmnibus(product_id, callback) {
        let data = {
            product_id: product_id
        };
        post(
            (response) => {
                callback(response);
            },
            data,
            "product/omnibus",
            false
        );
    },
    getCartRecommended(callback) {
        get((response) => {
            callback(response);
        }, "product/get-cart-recommended");
    },
    getEyesNewLipsProducts(callback) {
        get((response) => {
            callback(response);
        }, "product/get-eyes-new-lips-products");
    },
    getCategoryByUri(uri, callback) {
        post((response) => {
            callback(response);
        }, {uri: uri}, "category/get-by-uri");
    },
    getCategoryTypeByUri(uri, callback) {
        post((response) => {
            callback(response);
        }, {uri: uri}, "category/get-type-by-uri");
    },
    getProductByUri(uri, callback) {
        post((response) => {
            callback(response);
        }, {uri: uri}, "product/get-by-uri");
    },
    checkPaymentStatus(id, callback) {
        post(
            (response) => {
                callback(response);
            },
            {orderId: id},
            "order/check-payment-status"
        );
    },
    getExchange(callback) {
        if (exchange) {
            callback(exchange);
        }
        get((response) => {
            if (response.success) {
                exchange = response.exchangeRates;
            }
            callback(response);
        }, "exchange/get");
    },
    getDeliveryCosts(callback, cart, country, coupon_code = '') {
        let count = 0;
        let total = 0;
        cart.forEach(elem => {
            count += elem.quantity;
            total += elem.quantity*elem.price;
            // console.log(elem);
        });
        if (deliveryCosts[count + country]) {
            callback(deliveryCosts[count + country]);
        }
        post((response) => {
                if (response.success) {
                    deliveryCosts[count + country] = response;
                }
                callback(response);
            }, {
                count: count,
                country: country,
                coupon_code: coupon_code,
                total: total,
                cart: cart
            },
            "delivery-cost/get-all-v2");
    },
    getFreeDeliveryFromPrice(country, callback) {
        post(
            (response) => {
                callback(response);
            },
            {country: country},
            "delivery-cost/get-delivery-free-from"
        );
    },
    setBearerFromStorage(key) {
        bearer = key;
    },
    getMetaData(callback) {
        get((response) => {
            callback(response);
        }, "meta-data/get-all");
    },
    getCountries(callback) {
        if (countries) {
            callback(countries);
            return;
        }
        const requestParams = {
            method: 'get',
            headers: {
                Accept: "application/json",
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json",
            },
        };
        //?access_key=7643e632ee5285ff4d0e9a49adde22e6
        fetch(window.location.origin + '/data/countries.json', requestParams)
            .then((response) => {
                if (response && response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    // response.json().then(data => console.log(data));
                    return [];
                }
            })
            .then((response) => {
                //wyłącz loader
                // document.getElementById('loader').classList.remove("loadderActive");
                if (response.length) {
                    countries = response;
                }
                callback(response);
            })
            .catch((error) => console.error("Error:", error));
    },
    newsletter(email, callback) {
        post(
            (response) => {
                callback(response);
            },
            {email: email},
            "newsletter/register"
        );
    },
    getUrlParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    },


    getProductPriceBeforeDiscount(product) {
        if(product.discount_percent && product.original_price){
            return product.original_price;
        }
        return product.price;
    }
};

export default Request;
