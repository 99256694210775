import React from 'react';
import {Col, Row, InputGroup, Form} from 'react-bootstrap';
import colors from '../../colors/colors';
import {ReactComponent as Cart} from "../../svg/cart.svg";
import {ReactComponent as Search} from "../../svg/search.svg";
import {ReactComponent as User} from "../../svg/user.svg";
import {ReactComponent as Menu} from "../../svg/menu.svg";
// import {ReactComponent as SantaHat} from "../../svg/hat.svg";

// import { ReactComponent as Info } from "../../svg/info.svg";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {
    currentCategoryFetched,
    searchValueFetched,
    whiteHeaderFetched,
    currentTypeFetched,
    homePageFetched,
    cartFetched,
    configurationFetched, menuFetched
} from "../../actions";
import Request from '../../api/Request';
import {withRouter} from "react-router-dom";
import Icon from "../Icon";
import {calcCartItemsQuantity} from "../headers/MainHeader";
import AddedToCartPopup from "../addedToCart/AddedToCartPopup";
import {Tools} from "../../api/Tools";

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfoMenu: false,
            showSearchScreen: false,
            showShopMenu: false,
            brands: [],
            hints: [],
            menuWhite: false,
            showTopInfo: false,
            closeCartPopupTimeout: null,
            isScrolled: false
        };


        window.showTopInfo = function () {
            if (this.props.cart.length) {
                this.setState({ showTopInfo: true, closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000) });
            }
        }.bind(this);

        window.hideTopInfo = function () {
            this.setState({ showTopInfo: false, closeCartPopupTimeout: null });
        }.bind(this);
    }



    componentDidMount() {
        Request.getCategories(response => {
            if (response.success) {
                this.setState({brands: response.categories});
            }
        });
        //console.log('menu mount', this.props);
        this.setMenuColor();

        window.addEventListener('scroll', this.handleScroll.bind(this));

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll(){
        const isScrolled = (window.scrollY > 0);
        if(isScrolled !== this.state.isScrolled){
            this.setState({isScrolled: isScrolled});
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('menu update', this.props);
        this.setMenuColor();
    }

    setMenuColor() {
        // let menuWhite = (this.props.location.pathname == '/');
        let menuWhite = false;
        //(menuWhite);
        if (this.state.menuWhite != menuWhite) {
            this.setState({menuWhite: menuWhite})
        }
    }

    setShowInfoMenuValue() {
        if (this.state.showInfoMenu) {
            this.setState({showInfoMenu: false});
        } else {
            this.setState({showInfoMenu: true, showSearchScreen: false, showShopMenu: false});
        }
    }

    setShowSearchScreenValue() {
        if (this.state.showSearchScreen) {
            this.setState({showSearchScreen: false});
        } else {
            this.setState({showSearchScreen: true, showInfoMenu: false, showShopMenu: false});
        }
    }

    setShowShopMenuValue() {
        if (this.state.showShopMenu) {
            this.setState({showShopMenu: false});
        } else {
            this.setState({showShopMenu: true, showInfoMenu: false, showSearchScreen: false});
        }
    }

    closeAll() {
        this.setState({
            showInfoMenu: false,
            showSearchScreen: false,
            showShopMenu: false,
        });
        if (window.showAccountMenu) {
            window.showAccountMenu();
        }
    }

    setCategory(id) {
        this.props.currentCategoryFetched(id);
        this.props.searchValueFetched("");
    }

    setType(type) {
        this.props.currentTypeFetched(type);
        this.props.searchValueFetched("");
    }

    handleSearchValueChange(event) {
        const {value} = event.target;
        this.setState({searchInputValue: value});
        if (value.length > 2) {
            Request.getSearchHints(value, (response) => {
                this.setState({hints: response.hints});
            });
        }
    }


    render() {
        const infoMenuClass = this.state.showInfoMenu ? 'infoMenu' : 'infoMenu infoMenuHidden'
        const shopMenuClass = this.state.showShopMenu ? 'shopMenu MobileMenu__content' : 'shopMenu MobileMenu__content shopMenuHidden'
        const searchScreenClass = this.state.showSearchScreen ? 'searchScreenMobile' : 'searchScreenMobile searchScreenMobileHidden'
        let menuClass = this.state.menuWhite && !this.state.isScrolled ? 'MobileMenu MobileMenu--white' : 'MobileMenu';
        if (this.state.showShopMenu || this.state.showSearchScreen) {
            menuClass += ' MobileMenu--opened';
        }
        if (this.state.showSearchScreen) {
            menuClass += ' MobileMenu--search-opened';
        }

        if(this.props.homePage) {
            menuClass += ' MobileMenu--homepage';
        }

        const menuItem = {
            fontFamily: "Raleway",
            fontWeight: 900,
            color: colors.blue,
            fontSize: '20px',
            marginBottom: '25px',
        }

        const order = [3, 4, 6, 7, 14, 5];


        const showTopInfoClass = this.state.showTopInfo
            ? "topInfo"
            : "topInfo  topInfoHidden";

        const showTopInfoClassMobile = this.state.showTopInfo
            ? "topInfoMobile"
            : "topInfoMobile  topInfoHiddenMobile";

        return (
            <>

                <AddedToCartPopup
                    isMobile={this.props.isMobile}
                    showTopInfoClassMobile={showTopInfoClassMobile}
                    showTopInfoClass={showTopInfoClass}
                    cart={this.props.cart}
                    mouseEnter={() => {
                        this.setState({ closeCartPopupTimeout: clearTimeout(this.state.closeCartPopupTimeout) });
                    }}
                    mouseLeave={() => {
                        this.setState({ closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000) });
                    }}
                />


                <div className={menuClass} style={{flexWrap: 'wrap'}}>

                    {this.props.location.pathname == '/' && this.props.configuration.banner_info ?
                        <div className="MainHeader__info" style={{cursor: this.props.configuration.banner_info_link ? 'pointer' : 'initial'}} onClick={() => Tools.redirect(this.props.configuration.banner_info_link)}>
                            {this.props.configuration.banner_info}
                        </div>
                        : null}

                    <div className="MobileMenu__left">
                        <button className="Hamburger" type="button" onClick={this.setShowShopMenuValue.bind(this)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        {/*<Menu onClick={this.setShowShopMenuValue.bind(this)}/>*/}

                        <Link to="/" className="MobileMenu__logo-link">
                            <Icon className="MobileMenu__logo"/>
                            {/*<SantaHat className="SantaHat"/>*/}
                        </Link>
                    </div>
                    <div className="MobileMenu__right">
                        <Search onClick={this.setShowSearchScreenValue.bind(this)}/>
                        <Link to='/account'><User onClick={this.closeAll.bind(this)}/></Link>
                        <Link to='/cart' className="cartLink"><Cart onClick={this.closeAll.bind(this)}/>
                            {this.props.cart.length ?
                                <span className="cartLink__qty">{calcCartItemsQuantity(this.props.cart)}</span>
                                : null}
                        </Link>
                    </div>


                </div>








                <div className={shopMenuClass}>

                    {this.props.menu && typeof this.props.menu.map !== 'undefined' ?
                        <ul className="MobileMenu__list">
                            {this.props.menu.map((link, index) => {
                                return (
                                    <li key={index} className={"MobileMenu__item"}>
                                        <a href={link.link} className="MobileMenu__item__link" onClick={() => {this.closeAll();}}>
                                            {link.label}
                                        </a>
                                    </li>
                                )
                            })}

                        </ul>
                        : <></>
                    }






                    {/*<ul style={{listStyle: 'none', padding: 0}}>*/}
                    {/*    {this.state.brands.filter((category) =>*/}
                    {/*        category.name != 'Enhance'*/}
                    {/*        && category.name != 'Intraline'*/}
                    {/*        && category.name != 'Man'*/}
                    {/*        && category.name != 'All about eyes'*/}
                    {/*    ).sort(function (a, b) {*/}
                    {/*        return order.indexOf(a.id) - order.indexOf(b.id);*/}
                    {/*    }).map((category, id) => {*/}
                    {/*        return (*/}
                    {/*            <Link*/}
                    {/*                key={id}*/}
                    {/*                style={{textDecoration: 'none'}}*/}
                    {/*                to={"/products/" + category.uri}>*/}
                    {/*                <li*/}
                    {/*                    style={menuItem}*/}
                    {/*                    onClick={() => {*/}
                    {/*                        this.setShowShopMenuValue();*/}
                    {/*                        this.setType(category.type)*/}
                    {/*                    }}>*/}
                    {/*                    {category.name}</li>*/}
                    {/*            </Link>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</ul>*/}
                </div>
                <div className={searchScreenClass}>
                    <form onSubmit={() => {
                        return false;
                    }}>
                        <Form.Group controlId="search">
                            <InputGroup className="searchInput">
                                <Form.Control
                                    type="text"
                                    value={this.state.searchInputValue}
                                    onChange={this.handleSearchValueChange.bind(this)}
                                />
                            </InputGroup>
                            <div className="tips" style={{overflow: 'auto'}}>
                                {
                                    this.state.hints.map((hint) => (
                                        <span
                                            className='searchHint'
                                            onClick={() => {
                                                this.setState({searchInputValue: ''}, () => {
                                                    this.setShowSearchScreenValue();
                                                    this.setState({hints: []});
                                                    window.location.href = '/product/' + hint.uri;
                                                })
                                            }} style={{
                                            marginTop: '20px',
                                            color: colors.graphite,
                                            cursor: 'pointer',
                                            position: 'relative',
                                            display: 'block',
                                            width: 'max-content'
                                        }}>{hint.name}</span>
                                    ))
                                }
                            </div>
                            <Search
                                style={{
                                    width: "25px",
                                    marginTop: "23px",
                                    position: "absolute",
                                    top: '66px',
                                    left: "6vw",
                                }}
                            />
                        </Form.Group>
                    </form>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentCategory: state.currentCategory,
        white: state.whiteHeader,
        currentType: state.currentType,
        homePage: state.homePage,
        cart: state.cart,
        configuration: state.configuration,
        menu: state.menu,
    };
};
const mapDispatchToProps = {
    currentCategoryFetched,
    searchValueFetched,
    whiteHeaderFetched,
    currentTypeFetched,
    homePageFetched,
    cartFetched,
    configurationFetched,
    menuFetched
}; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileMenu));
