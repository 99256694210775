export const Storage = {
    getUserIpCountryData(){
        let defaultData = {
            ip: '',
            isPL: true
        }
        let data = JSON.parse(window.localStorage.getItem('user_ip_country_data'));
        if(!data){
            return defaultData;
        }
        return data;

    },
    setUserIpCountryData(data){
        window.localStorage.setItem('user_ip_country_data', JSON.stringify(data));
    },
    getVatNumber(){
        const vat_number = window.localStorage.getItem('checkout_invoice_vat_number');
        if(!vat_number) return '';
        return vat_number;
    },
    setVatNumber(vat_number){
        window.localStorage.setItem('checkout_invoice_vat_number', vat_number);
    }
}
