import { combineReducers } from "redux";
import {isMobile} from "./isMobile";
import {isClientPL} from "./isClientPL";
import {cart} from "./cart";
import {user} from "./user";
import {currentCategory} from "./currentCategory";
import {searchValue} from "./searchValue";
import {historicalOrder} from "./historicalOrder";
import {deliveryInfo} from "./deliveryInfo";
import {lastOrderId} from "./lastOrderId";
import {whiteHeader} from "./whiteHeader";
import {currentType} from "./currentType";
import {productToShow} from "./productToShow";
import {cartPage} from "./cartPage";
import {homePage} from "./homePage";
import {userPage} from "./userPage";
import {locale} from "./locale";
import {showCurrencyPopup} from "./showCurrencyPopup";
import {showNewsletterPopup} from "./showNewsletterPopup";
import {euCountry} from "./euCountry";
import {stateCountry} from "./stateCountry";
import {coupon} from "./coupon";
import {menu} from './menu';
import {configuration} from './configuration';

export default combineReducers ({
    isMobile,
    cart,
    user,
    currentCategory,
    searchValue,
    historicalOrder,
    deliveryInfo,
    lastOrderId,
    whiteHeader,
    currentType,
    productToShow,
    cartPage,
    homePage,
    userPage,
    locale,
    showCurrencyPopup,
    showNewsletterPopup,
    euCountry,
    stateCountry,
    coupon,
    isClientPL,
    menu,
    configuration
});
