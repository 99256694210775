const euCountries = [
    'Austria',
    'Lithuania',
    'Belgium',
    'Luxembourg',
    'Bulgaria',
    'Latvia',
    'Croatia',
    'Malta',
    'Cyprus',
    'Germany',
    'Czech Republic',
    'Denmark',
    'Portugal',
    'Estonia',
    'Romania',
    'Finland',
    'Slovakia',
    'France',
    'Slovenia',
    'Greece',
    'Sweden',
    'Spain',
    'Hungary',
    'The Netherlands',
    'Italy',
    'Ireland',
    'Poland',
];

export default euCountries;
