import React from "react";
import {Row, Col, Form, InputGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import colors from "../../colors/colors";
import SearchScreen from './SearchScreen';
import {connect} from "react-redux";
import {
    isMobileFetched,
    currentCategoryFetched,
    cartFetched,
    searchValueFetched,
    whiteHeaderFetched,
    currentTypeFetched,
    cartPageFetched,
    localeFetched,
    showCurrencyPopupFetched,
    showNewsletterPopupFetched,
    homePageFetched,
    menuFetched,
    configurationFetched,
    euCountryFetched, isClientPLFetched
} from "../../actions";
import Request from '../../api/Request';
import {ReactComponent as Cart} from "../../svg/cart.svg";
import {ReactComponent as Search} from "../../svg/search.svg";
import {ReactComponent as User} from "../../svg/user.svg";
import {ReactComponent as CartWhite} from "../../svg/cartWhite.svg";
import {ReactComponent as SearchWhite} from "../../svg/searchWhite.svg";
import {ReactComponent as UserWhite} from "../../svg/userWhite.svg";
import {ReactComponent as CartPink} from "../../svg/cartPink.svg";
import {ReactComponent as UserPink} from "../../svg/userPink.svg";
import {ReactComponent as SantaHat} from "../../svg/hat.svg";
import SubMenu from "./SubMenu";
import AddedToCartPopup from "../addedToCart/AddedToCartPopup";
import Icon from "../Icon";
import {X} from "react-bootstrap-icons";
import {withRouter} from "react-router-dom";
import CustomDropdown from "../blocks/CustomDropdown";
import euCountries from "../../euCountries";
import stateCountries from "../../stateCountries";
import {deepCopy} from "../../App";
import {Tools} from "../../api/Tools";
import {Translator} from "../translator/Translator";

import ChristmasImage from '../../img/christmas.webp';
import Bauble from "../christmas/Bauble";
import {translate} from "../translator/translate";
import HTMLReactParser from "html-react-parser";

export function calcCartItemsQuantity(cart){
  let count = 0;
  cart.forEach((item) => {
    count += item.quantity;
  })
  return count;
}

class MainHeader extends React.Component {
    constructor(props) {
        super(props);

        // window.closeMenu = () => {
        //     if (this.state.showSubMenuBG && !this.state.showSearch) {
        //         for (let i = 0; i < window.hideSubMenu.length; i++) {
        //             window.hideSubMenu[i]();
        //         }
        //         this.hideSubMenuBackgroundClass();
        //     }
        // }

        window.showTopInfo = function () {
            if (this.props.cart.length) {
                this.setState({showTopInfo: true, closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000)});
            }
        }.bind(this);

        window.hideTopInfo = function () {
            this.setState({showTopInfo: false, closeCartPopupTimeout: null});
        }.bind(this);


        this.state = {
            showSearch: false,
            brands: [],
            showTopInfo: false,
            subMenuBackgroundClass: "subMenuBackground",
            subMenuWiteBg: 'subMenuWiteBg',
            showSubMenuBG: false,
            countries: [],
            searchInputValue: "",
            hints: [],
            searchInputLenght: false,
            hideMenu: false,
            cartTimeout: null,
            closeCartPopupTimeout: null,
            showBrandList: null,
            currency: this.props.locale.currency,
            menuBackgroundHeight: 0,
            mouseEntered: false
        };
    }

    componentDidMount() {
        Request.getCategories(response => {
            if (response.success) {
                this.setState({brands: response.categories});
            }
        });

        Request.getCountries(response => {
            this.setState({countries: response});
        });

    }

    getFlag() {
        if (this.state.countries.length) {
            let i = 0;
            for (i; i < this.state.countries.length; i++) {
                if (this.state.countries[i].name == this.props.locale.country) {
                    return "https://www.countryflags.io/" + this.state.countries[i].alpha2Code + "/flat/64.png";
                }
            }
        }
    }

    // hideSearchScreen() {
    //   this.setState({ showSearch: false });
    // }

    // showSearchScreen() {
    //   this.setState({ showSearch: true });
    // }

    showSearchScreen() {
        this.setState({
            showSearch: true,
            subMenuBackgroundClass: "subMenuBackground subMenuBackgroundShown",
            subMenuWiteBg: 'subMenuWiteBg subMenuWiteBgShown',
            hideMenu: true,
        });
        setTimeout(() => {
            this.setState({
                searchInputLenght: true, showSubMenuBG: true,
            });
            document.getElementById('search').focus();
        }, 120);
    }

    hideSearchScreen() {
        this.setState({
            showSubMenuBG: false,
            searchInputLenght: false,
            showSearch: false,
            hints: [],
        });

        setTimeout(() => {
            this.setState({
                subMenuBackgroundClass: "subMenuBackground",
                subMenuWiteBg: 'subMenuWiteBg',
                hideMenu: false,
            })
        }, 500);


        setTimeout(() => {
            this.setState({
                searchInputValue: "",
            })
        }, 1000);

    }

    setCategory(id) {
        this.props.currentCategoryFetched(id);
        this.props.searchValueFetched("");
    }

    setType(type) {
        this.props.currentTypeFetched(type);
        this.props.searchValueFetched("");
    }

    showSubMenuBackgroundClass() {
        this.setState({
            subMenuBackgroundClass: "subMenuBackground subMenuBackgroundShown",
            subMenuWiteBg: 'subMenuWiteBg subMenuWiteBgShown',
            showSubMenuBG: true,
            menuBackgroundHeight: 100
        });
    }

    hideSubMenuBackgroundClass() {

        this.setState({
            subMenuBackgroundClass: "subMenuBackground",
            subMenuWiteBg: 'subMenuWiteBg',
            showSubMenuBG: false,
            menuBackgroundHeight: 0
        });
    }


    handleSearchValueChange(event) {
        const {value} = event.target;
        this.setState({searchInputValue: value});
        if (value.length > 2) {
            Request.getSearchHints(value, (response) => {
                let hints = response.hints;
                this.setState({hints: hints});
            });
        }
    }

    setShowBranListNumber(id) {
        this.setState({showBrandList: id});
    }

    setShowBranListNull() {
        this.setState({showBrandList: null});
    }


    handleCurrencyChange(currency) {
        let locale = this.props.locale;
        locale.currency = currency;
        this.setState({currency: currency});
        this.props.localeFetched({...locale});
        window.localStorage.setItem('locale', JSON.stringify(locale));
    }

    handleDeliveryCountryChange(country) {
        let locale = this.props.locale;
        locale.country = country;
        this.props.localeFetched({...locale});
        // console.log(country);
        this.props.euCountryFetched(Request.countryNameIsEu(country));
        window.localStorage.setItem('locale', JSON.stringify(locale));
        this.props.isClientPLFetched(Request.isClientPL());

    }


    countriesToDropdown() {
        return this.state.countries.map((country) => {
            return {value: country.name}
        });
    }

    handleLinkClick(ev, link) {
        ev.stopPropagation();
        let category = this.findCategory(link.category);
        let brand = this.findBrand(link.category, link.brand);

        if(link.brand){
            if(category && brand){
                this.setType(category.type);
                this.setCategory(brand);
            }

        }else if(link.brand === null && link.category){
            if(category){
                this.setType(category.type);
            }
        }else{

        }

        this.setShowBranListNull();
        this.hideSubMenuBackgroundClass();

    }

    findCategory(id_category){
        return this.state.brands.find((category) => {return category.id == id_category});
    }

    findBrand(id_category, id_brand){
        let category = this.findCategory(id_category);
        if(category){
            return category.brands.find((brand) => {return brand.brandId == id_brand});
        }
        return null;
    }

    linkOnMouseEnter(index)
    {
        this.setState({mouseEntered: true})
        this.props.whiteHeaderFetched(false);


        setTimeout(() => {
            const submenu_bgs = document.querySelectorAll(`.MainMenu__submenu__bg`);
            if(!submenu_bgs.length){
                return;
            }
            submenu_bgs.forEach((submenu_bg) => {

                let x = submenu_bg.getBoundingClientRect().x;
                if(x > 0){
                    submenu_bg.style.left = -x+25+'px';
                }
                // console.log();
            });
        }, 0);


        return;


        // let menu = JSON.parse(JSON.stringify(this.props.menu));
        // menu[index].mouseTimeout = setTimeout(() => {
        //     menu.hover = true;
        //     this.props.menuFetched(menu);
        //     this.props.whiteHeaderFetched(false);
        //     // this.setState({
        //     //     menuBackgroundHeight: 100,
        //     // });
        //     this.showSubMenuBackgroundClass();
        // }, 1000);
    }
    linkOnMouseLeave(index){
        this.setState({mouseEntered: false})
        if(this.props.location.pathname == '/'){
            // this.props.whiteHeaderFetched(true);
        }
        return;

        // if(this.props.menu[index].mouseTimeout){
        //     clearTimeout(this.props.menu[index].mouseTimeout);
        //     this.props.menu[index].mouseTimeout = null;
        //     this.props.menuFetched(deepCopy(this.props.menu));
        // }
    }
    menuMouseLeave(){
        if(this.state.showSearch) return false;
        let menu = deepCopy(this.props.menu);
        menu.hover = false;
        this.props.menuFetched(menu);
        this.hideSubMenuBackgroundClass();
    }

    getSubmenuColumnsCount(links){
        if(links.length > 12){
            return 2;
        }
        if(links.length > 3){
            return 1;
        }

        return 0;
    }
    getSubmenuColumn(links, column_index){
        let colsTotal = this.getSubmenuColumnsCount(links);
        if(column_index > colsTotal) return false;
        let linksTotal = links.length;
        let columnSize = Math.ceil(linksTotal/(colsTotal+1));
        return links.slice(column_index*columnSize, column_index*columnSize+columnSize);
    }

    render() {

        //console.log(this.props);

        const icon = {
            height: '23px',
            width: 'auto',
            marginTop: '27px',
            cursor: 'pointer',
            left: 0,
            posiion: 'relative'
        };

        const cartNumber = {
            fontSize: "9px",
            borderRadius: "28px",
            textAlign: "center",
            letterSpacing: 0,
            fontWeight: "bold",
            width: "13px",
            position: "relative",
            top: "-26px",
            left: "15px",
            background: this.props.cartPage ? colors.pink : this.props.white ? colors.white : colors.graphite,
            color: this.props.white ? colors.graphite : colors.white,
            textDecoration: 'none',
        };

        const showTopInfoClass = this.state.showTopInfo
            ? "topInfo"
            : "topInfo  topInfoHidden";

        const showTopInfoClassMobile = this.state.showTopInfo
            ? "topInfoMobile"
            : "topInfoMobile  topInfoHiddenMobile";
        // : 'topInfo';

        const searchIconClass = !this.state.showSearch
            ? 'navIconIPMB searchScreenIcon'
            : 'navIconIPMB searchScreenIcon searchScreenIconLeft';

        const searchIconClassWhite = !this.state.showSearch
            ? 'navIconIPMBWhite searchScreenIconWhite'
            : 'navIconIPMBWhite searchScreenIconWhite searchScreenIconLeftWhite';

        const searchInputlass = !this.state.searchInputLenght
            ? 'searchInput'
            : 'searchInput searchInputActive';
        const mainMenuClass = this.props.white
            ? 'MainMenu'
            : 'MainMenu MainMenu--white';

        const order = [3, 4, 6, 7, 14, 5];


        // console.log(this.props);
        // console.log(this.state);

        let mainHeaderClass = this.props.white ? "MainHeader-main-wrapper" : "MainHeader-main-wrapper MainHeader-main-wrapper--white";
        if(this.state.mouseEntered){
            mainHeaderClass += ' is-hovered';
        }
        return (
            <div className={mainHeaderClass} onMouseLeave={this.menuMouseLeave.bind(this)}>
                <AddedToCartPopup
                    isMobile={this.props.isMobile}
                    showTopInfoClassMobile={showTopInfoClassMobile}
                    showTopInfoClass={showTopInfoClass}
                    cart={this.props.cart}
                    mouseEnter={() => {
                        this.setState({closeCartPopupTimeout: clearTimeout(this.state.closeCartPopupTimeout)});
                    }}
                    mouseLeave={() => {
                        this.setState({closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000)});
                    }}
                />
                <div
                    className={this.state.subMenuBackgroundClass}
                    style={{
                        // maxHeight: '90vhx',
                        top: "0",
                        left: "0",
                    }}
                >
                    <div
                        className={this.state.subMenuWiteBg}
                        style={{
                            backgroundColor: colors.white,
                            height: this.state.menuBackgroundHeight+"px",
                            width: '100%',
                            position: 'absolute',
                            transition: '0.5s',
                            left: 0,
                            boxShadow: '0px 3px 8px #35749033',
                        }}
                    ></div>


                    {this.props.location.pathname == '/' && this.props.configuration.banner_info ?
                        <div className="MainHeader__info" style={{cursor: this.props.configuration.banner_info_link ? 'pointer' : 'initial'}} onClick={() => Tools.redirect(this.props.configuration.banner_info_link)}>
                            {this.props.configuration.banner_info}
                            {/*{Request.getShopInfoMessage()}*/}
                        </div>
                        : null}

                    <Row style={{
                        height: '30px',
                        background: this.props.white ? 'rgba( 255, 255 ,255 ,0.1)' : 'rgb(228, 236, 240)',
                    }}>
                        <Col md={12}>

                            <div className="MainHeader__top">
                                <div
                                    className={this.props.white ? "MainHeader__top__text" : "MainHeader__top__text MainHeader__top__text--white"}>
                                    <Translator location={"MainHeader.free_shipping"}/>
                                </div>
                                <div
                                    className={this.props.white ? "currencySelectWrapper" : "currencySelectWrapper currencySelectWrapper--white"}>

                                    <CustomDropdown
                                        value={this.props.locale.country}
                                        onChange={this.handleDeliveryCountryChange.bind(this)}
                                        options={this.countriesToDropdown()}
                                        prefix={translate("MainHeader.shipping_to")}
                                    />

                                    <CustomDropdown
                                        className={'CustomDropdown--uppercase'}
                                        value={this.props.locale.currency}
                                        onChange={this.handleCurrencyChange.bind(this)}
                                        options={Request.getAvailableCurrencies()}
                                        prefix={translate('MainHeader.choose_currency')}
                                    />

                                </div>

                            </div>

                        </Col>

                    </Row>




                    <div className="MainHeader">
                        <Link to="/" className="MainHeader__logo">
                            {this.state.showSubMenuBG == false && this.props.white ?
                                <Icon style={{
                                        width: '160px',
                                        marginLeft: '-14px',
                                        filter: 'invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg) brightness(450%) contrast(100%)'
                                    }}/>
                                : <Icon
                                    style={{
                                        marginTop: "10px", width: '160px', marginLeft: '-14px'
                                    }}
                                />
                            }
                            {/*<Bauble/>*/}
                        </Link>


                        {this.state.hideMenu ?
                            <div className="MainHeader__center MainHeader__center--search">
                                <form onSubmit={(ev) => {
                                    ev.preventDefault();
                                    if (!this.state.searchInputValue) {
                                        return false;
                                    }
                                    window.location.pathname = '/search/' + this.state.searchInputValue;
                                    return false;
                                }}>
                                    <Form.Group controlId="search" style={{position:'relative'}}>
                                        <InputGroup
                                            className={searchInputlass}
                                            style={{
                                                width: "0vw",
                                                marginLeft: "auto",
                                                marginRight: '0px',
                                                top: "15px",
                                                transition: '1s',
                                            }}
                                        >
                                            <Form.Control
                                                type="text"
                                                value={this.state.searchInputValue}
                                                onChange={this.handleSearchValueChange.bind(this)}
                                            />
                                        </InputGroup>
                                        <div
                                            className={this.state.hints.length ? "tips" : "tips tips--hidden"}
                                            style={{
                                                color: colors.white,
                                                width: "70vw",
                                                margin: "auto",
                                                textAlign: "left",
                                                marginTop: "30px",
                                                fontSize: "18px",
                                            }}
                                        >
                                            {
                                                this.state.hints.map((hint, hintIndex) => (
                                                    <span
                                                        className='searchHint'
                                                        key={hintIndex}
                                                        onClick={() => {
                                                            if(hint.is_brand){
                                                                window.location.href = '/brand/' + hint.uri;
                                                            }else{
                                                                window.location.href = '/product/' + hint.uri;
                                                            }
                                                        }} style={{
                                                        color: colors.graphite,
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        display: 'block',
                                                        width: 'max-content'
                                                    }}>{hint.name}</span>
                                                ))
                                            }
                                        </div>
                                    </Form.Group>
                                </form>
                            </div> :
                            <div className="MainHeader__center MainHeader__center--menu">
                                {this.props.menu && typeof this.props.menu.map !== 'undefined' ?
                                    <ul className={mainMenuClass}>
                                        {this.props.menu.map((link, index) => {
                                            return (
                                                <li key={index} className={"MainMenu__item"}
                                                    onMouseEnter={(ev) => {this.linkOnMouseEnter(index)}}
                                                    onMouseLeave={(ev) => {this.linkOnMouseLeave(index)}}
                                                >
                                                    <Link to={link.link} className={(link.link === '/discounts') ? 'MainMenu__link hover_underline MainMenu__link--discount' : 'MainMenu__link hover_underline'} onClick={(ev) => this.handleLinkClick(ev, link)}>
                                                        <span className="hover_underline__target">{link.label}</span>
                                                    </Link>
                                                    {link.children.length ?
                                                    <div className="MainMenu__submenu">
                                                        <div className="MainMenu__submenu__bg"></div>
                                                        {[0,1,2].map((value, column_index) => {
                                                            let className = "MainMenu__submenu__column MainMenu__submenu__column--"+column_index;
                                                            let columnLinks = this.getSubmenuColumn(link.children, column_index);
                                                            let forceOneColumn = link.id === 'category-14';
                                                            if(forceOneColumn){
                                                                columnLinks = link.children;
                                                                if(column_index > 0) columnLinks = [];
                                                            }
                                                            if(Array.isArray(columnLinks)){
                                                                columnLinks = columnLinks.filter((item) => {
                                                                    return item.link.indexOf('product--') === -1
                                                                })
                                                            }

                                                            if(!columnLinks || columnLinks.length === 0) return null;
                                                            return (
                                                                <div className={className} key={column_index}>
                                                                    {columnLinks.map((child, subLinkIndex) => {
                                                                        return (
                                                                            <Link key={subLinkIndex} className="MainMenu__submenu__column__link hover_underline" onClick={(ev) => this.handleLinkClick(ev, child)} to={child.link}>
                                                                                <span className="hover_underline__target">{HTMLReactParser(child.label)}</span>
                                                                            </Link>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                        })}

                                                        {link.children.filter((item) => {return item.link.indexOf('product--') === 0}).map((child, subLinkIndex) => {
                                                            const id = child.link.replace('product--', '');

                                                            const productData = Request.getProductByID(id)
                                                            if(!productData) return null;
                                                            //console.log(productData);
                                                            return (
                                                                <Link to={'/product/'+productData.uri} key={subLinkIndex} className="MainMenu__submenu__column__product">
                                                                    <img className="MainMenu__submenu__column__product__img" src={productData.image} alt={productData.name}/>
                                                                    <span className="MainMenu__submenu__column__product__label">{child.label}</span>
                                                                    <span className="MainMenu__submenu__column__product__name">{productData.name}</span>
                                                                </Link>
                                                            )
                                                        })}


                                                    </div>
                                                    : <></>
                                                    }
                                                </li>
                                            )
                                        })}

                                    </ul>
                                : <></>
                                }

                            </div>}

                        <div className="MainHeader__actions">

                            <div className="MainHeader__actions__item">
                                {this.state.showSubMenuBG == false && this.props.white ? <SearchWhite
                                        className={searchIconClassWhite}
                                        onClick={this.showSearchScreen.bind(this)}
                                        style={icon}
                                    /> :
                                    <Search
                                        className={searchIconClass}
                                        onClick={this.showSearchScreen.bind(this)}
                                        style={icon}
                                    />}
                                {this.state.showSearch ?
                                    <X
                                        className='navIconIPMB'
                                        onClick={this.hideSearchScreen.bind(this)}
                                        color={colors.graphite}
                                        size={45}
                                        style={{
                                            position: "absolute",
                                            top: "-6px",
                                            right: "-10px",
                                            cursor: "pointer",
                                        }}
                                    /> : <></>}
                            </div>
                            <div className="MainHeader__actions__item MainHeader__actions__item--cart">
                                <Link className="cartNumber" to="/cart"
                                      onMouseEnter={() => this.setState({cartTimeout: setTimeout(window.showTopInfo, 200)})}
                                      onMouseLeave={() => this.setState({cartTimeout: clearTimeout(this.state.cartTimeout)})}
                                >
                                    {this.state.showSubMenuBG == false && this.props.white ?
                                        <CartWhite className='navIconIPMBWhite' style={icon}/>
                                        : this.props.cartPage
                                            ? <CartPink style={icon}/>
                                            : <Cart className='navIconIPMB' style={icon}/>
                                    }
                                    {this.props.cart.length > 0 ?
                                        <p className="cartQuantity">{calcCartItemsQuantity(this.props.cart)}</p>
                                        : <></>
                                    }
                                </Link>
                            </div>
                            <div className="MainHeader__actions__item">
                                {/* <Link to="/account"> */}
                                <Link to="/login">
                                    {this.state.showSubMenuBG == false && this.props.white ?
                                        <UserWhite className='navIconIPMBWhite' style={icon}/> :
                                        this.props.userPage ? <UserPink style={icon}/> :
                                            <User className='navIconIPMB' style={icon}/>}
                                </Link>
                            </div>
                        </div>

                    </div>




                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.isMobile, // (1)
        currentCategory: state.currentCategory,
        cart: state.cart,
        white: state.whiteHeader,
        currentType: state.currentType,
        cartPage: state.cartPage,
        userPage: state.userPage,
        locale: state.locale,
        showNewsletterPopup: state.showNewsletterPopup,
        showCurrencyPopup: state.showCurrencyPopup,
        homePage: state.homePage,
        menu: state.menu,
        configuration: state.configuration,
        euCountry: state.euCountry,
        isClientPL: state.isClientPL,
    };
};
const mapDispatchToProps = {
    isMobileFetched,
    currentCategoryFetched,
    cartFetched,
    searchValueFetched,
    whiteHeaderFetched,
    currentTypeFetched,
    cartPageFetched,
    localeFetched,
    showCurrencyPopupFetched,
    showNewsletterPopupFetched,
    homePageFetched,
    menuFetched,
    configurationFetched,
    euCountryFetched,
    isClientPLFetched
}; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));
