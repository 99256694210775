import React from "react";
import {Container} from "react-bootstrap";

export default class FullSiteWrapper extends React.Component {

    constructor(props){
        super(props);
    }

    isNewLayout(){
        const paths = [
            '/payment-info',
            '/',
            '/company-info',
            '/delivery-info',
            '/contact',
            '/about',
            '/login',
            '/register',
            '/cart',
            '/pre-checkout',
            '/checkout',
            '/thank-you',
            '/all-products',
            '/discounts',
            '/skin-boosting',
            '/account',
            '/history',
            '/terms-and-conditions',
            '/privacy-policy'
        ];
        const pathsStartsWith = [
            '/product',
            '/products',
            '/brand',
            '/article',
            '/search'
        ];
        if(paths.indexOf(this.props.location) !== -1){
            return true;
        }
        for(let i = 0; i < pathsStartsWith.length; i++){
            if(this.props.location.startsWith(pathsStartsWith[i])){
                return true;
            }
        }


        return false;
    }

    render() {

        return (

            <>
                {!this.isNewLayout() ?
                    <Container style={{maxWidth: "100vw"}}>
                        {this.props.children}
                    </Container>
                    :
                    <main className="siteWrapper">
                        {this.props.children}
                    </main>
                }
            </>


        );
    }
}
