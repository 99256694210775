import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { withRouter } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import Request from '../../api/Request';

class PageChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metaData: [],
        };
    }

    componentDidMount() {
        Request.getMetaData(response => this.setState({ metaData: response.metaData }));
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
            ReactPixel.pageView();
        }
    }

    getMetaForPage() {
        if (this.state.metaData.length == 0) {
            return {};
        }
        const typeToPathname = {
            '/': 0,
            '/about': 1,
            '/company-info': 2,
            '/delivery-info': 3,
            '/payment-info': 4,
            '/privacy-policy': 5,
            '/terms-and-conditions': 6,
            '/contact': 7,
        }
        const type = typeToPathname[this.props.location.pathname];
        let defaultMeta = {};
        let specificMeta = {};
        for (let i = 0; i <= 7; i++) {
            if (this.state.metaData[i].type == 0) {
                defaultMeta = this.state.metaData[i];
            }
            if (this.state.metaData[i].type == type) {
                specificMeta = this.state.metaData[i];
            }
        }
        let meta = {
            title: specificMeta.title || defaultMeta.title,
            description: specificMeta.description || defaultMeta.description,

        }

        if (this.props.location.pathname.split('/').length < 3 && typeof type === 'undefined') {
            meta.meta = {
                name: {
                    robots: 'noindex,nofollow'
                }
            };
        }
        return meta;
    }

    render() {
        const defaultMeta = {
            ...this.getMetaForPage(),
            canonical: window.location.href,
        };

        return <DocumentMeta {...defaultMeta} />;
    }
}

export default withRouter(PageChange);