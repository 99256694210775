import React from "react";
import { connect } from "react-redux";
import { userFetched, localeFetched, isClientPLFetched } from "../../actions";
import Request from "../../api/Request";

class Price extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         exchanges: [],
        };
      }

  componentDidMount(){
      Request.getExchange((response) => {
        if (response.success) {
          this.setState({ exchanges: response.exchangeRates });
        }
      });
  }

  priceExchange() {
    let price = parseFloat(this.props.price);
    if(this.props.isClientPL && !this.props.skipVatPL){ //ADDITIONAL VAT TO PL CLIENTS
      price *= 1.08;
    }

    if (this.props.locale.currency == "eur") {
      price = price * 1;
      return  "€ " + price.toFixed(2);
    } else if (this.props.locale.currency == "pln") {
      price = price * this.state.exchanges.exchangePLN;
      return  "zł " + price.toFixed(2);
    } else if (this.props.locale.currency == "usd") {
      price = price * this.state.exchanges.exchangeUSD;
      return  "$ " + price.toFixed(2);
    } else if (this.props.locale.currency == "gbp") {
      price = price * this.state.exchanges.exchangeGBP;
      return  "£ " + price.toFixed(2);
    }
  }

  render() {
    return <>{this.priceExchange()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    locale: state.locale,
    isClientPL: state.isClientPL
  };
};
const mapDispatchToProps = { userFetched, localeFetched, isClientPLFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(Price);
